<template>
  <div v-if="$auth.check()">
    <v-navigation-drawer v-model="drawer" app :mini-variant="!is_mobile" :clipped="!is_mobile" :permanent="!is_mobile" color="primary" dark :expand-on-hover="!is_mobile" >
    <!-- <v-navigation-drawer disable-resize-watcher v-model="drawer" clipped app> -->
      <v-list-item
        :to="'/account'"
        active-class="primary white--text"
        two-line
      >
        <v-list-item-action>
          <v-tooltip bottom :disabled="!alert">
            <template v-slot:activator="{ on, attrs }">
              <v-badge bottom :color="alert ? 'red' : ''" overlap :icon="alert ? 'mdi-exclamation-thick' : ''" >
                <v-icon v-if="user.avatar_url == null" v-bind="attrs" v-on="on">mdi-account</v-icon>
                <v-avatar v-if="user.avatar_url != null" size="27" v-bind="attrs" v-on="on">
                  <img :src="user.avatar_url"/>
                </v-avatar>
              </v-badge>
            </template>
            <span>Dados incompletos.</span>
          </v-tooltip>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            <b>{{user.name}}</b>
          </v-list-item-title>
          <v-list-item-subtitle>
            <span>{{user.role.translated}}</span>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list dense nav>
        <v-list-item
          v-for="(link,i) in links"
          :key="i"
          :to="link.to"
          active-class="primary white--text"
        >
          <v-list-item-action>
            <v-icon>{{ link.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-title>
            {{link.text}}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import { drawer } from "@/router/links";
// import { studentDrawer } from "@/router/studentLinks";
// import { coordinatorDrawer } from "@/router/coordinatorLinks";
// import { techSecretaryDrawer } from "@/router/techSecretaryLinks";
import resize from "@/mixins/resize";

export default {
  name: 'Drawer',
  mixins: [resize],
  data() {
    return {
      links: '',
      alert: false
    };
  },
  computed: {
    drawer: {
      get() {
        return this.$store.getters["App/getDrawer"];
      },
      set(val) {
        this.setDrawer(val);
      }
    },
    ...mapState({
      user: state=> state.User.user
    })
  },
  methods: {
    setLinks(){
      this.links = drawer.filter(el=> el.permissions.includes(this.user.role.name))
    },
    ...mapMutations({
      setDrawer: "App/setDrawer"
    })
  },
  watch: {
    user(nv,ov) {
      if (nv){
        this.setLinks()
      } else if (nv == false){
    
      }
    }
  },
};
</script>